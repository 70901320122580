module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Pacific Crest Healthcare","short_name":"PCH","start_url":"/","background_color":"#ffffff","theme_color":"rgb(14, 116, 188)","display":"minimal-ui","icon":"/opt/build/repo/static/pci.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"30349f8e3c6d4f8fa04c953d3bb0a059"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
